import { useRef, useMemo, useState } from 'react';

function logger({ getState }) {
	return (next) => (action) => {
		console.groupCollapsed('Logger Dashboard');
		console.log('%c Action:', 'color: blue', action);
		console.log('%c Previous State:', 'color: red', getState());
		// console.log('%c Current State:', 'color: green', test);
		console.groupEnd();

		return next(action);
	};
}

// A middleware has type (dispatch, getState) => nextMw => action => action
function enhanceDispatch({ getState, stateDispatch }) {
	return (...middlewares) => {
		let dispatch;
		const middlewareAPI = {
			getState,
			dispatch: (action) => dispatch(action),
		};
		dispatch = middlewares
			.map((m) => m(middlewareAPI))
			.reduceRight((next, mw) => mw(next), stateDispatch);
		return dispatch;
	};
}

function createUseMiddlewareReducer(middlewares) {
	return (reducer, initState, initializer = (s) => s) => {
		const [state, setState] = useState(initializer(initState));
		const stateRef = useRef(state); // stores most recent state
		const dispatch = useMemo(
			() =>
				enhanceDispatch({
					getState: () => stateRef.current, // access most recent state
					stateDispatch: (action) => {
						stateRef.current = reducer(stateRef.current, action); // makes getState() possible
						setState(stateRef.current); // trigger re-render
						return action;
					},
				})(...middlewares),
			[middlewares, reducer]
		);
		return [state, dispatch];
	};
}

export { logger, createUseMiddlewareReducer };
