import authReducer from './auth';
import deviceReducer from './device';
import flowReducer from './flow';
import logsReducer from './logs';
import notificationReducer from './notification';
import triggerReducer from './trigger';
import { logger } from './middlewares';

export const initialState = {
	auth: authReducer.initialState,
	device: deviceReducer.initialState,
	flow: flowReducer.initialState,
	logs: logsReducer.initialState,
	notification: notificationReducer.initialState,
	trigger: triggerReducer.initialState,
};

export default function mainReducer(state, action) {
	// Receiving previous state here
	const { auth, device, flow, logs, notification, trigger } = state;

	// Receiving current state here
	const currentState = {
		auth: authReducer.reducer(action, auth),
		device: deviceReducer.reducer(action, device),
		flow: flowReducer.reducer(action, flow),
		logs: logsReducer.reducer(action, logs),
		notification: notificationReducer.reducer(action, notification),
		trigger: triggerReducer.reducer(action, trigger),
	};

	// Middlewares
	logger(action, state, currentState);

	return currentState;
}
