import React, { useEffect } from 'react';
import { useLogs } from 'hooks';

function Logs() {
	const { /* error, isLoading, */ items, handleGet } = useLogs();

	useEffect(() => {
		handleGet();
	}, []);

	const tableHeader = () => {
		return (
			<tr>
				<th scope="col">Time</th>
				<th scope="col">Level</th>
				<th scope="col">Namespace</th>
				<th scope="col">Message</th>
			</tr>
		);
	};

	const tableItem = ({ id, timestamp, level, namespace, message, data }) => {
		let rowClass;

		switch (level) {
			case 'debug':
				rowClass = 'table-info';
				break;
			case 'error':
				rowClass = 'table-danger';
				break;
			case 'warn':
				rowClass = 'table-warning';
				break;
			default:
				rowClass = '';
		}

		const time = new Date(timestamp);
		const newData = data && (
			<>
				<br />
				<pre>
					<code>{JSON.stringify(data, null, 2)}</code>
				</pre>
			</>
		);

		return (
			<tr key={id} className={rowClass}>
				<th scope="row">{time.toLocaleString()}</th>
				<td>{level}</td>
				<td>{namespace}</td>
				<td>
					{message}
					{newData}
				</td>
			</tr>
		);
	};

	return (
		<main className="container">
			<h1>Logs</h1>

			<table className="table table-hover table-responsive-md">
				<thead>{tableHeader()}</thead>
				<tbody>{items && items.map((item) => tableItem(item))}</tbody>
			</table>
		</main>
	);
}

export default Logs;
