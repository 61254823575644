import * as TYPE from './types';

export function handleAdd(title, message, delay) {
	return (dispatch) => {
		dispatch({
			type: TYPE.ADD,
			payload: {
				timestamp: new Date(),
				title,
				message,
				delay,
			},
		});
	};
}

export function handleDelete(index) {
	return (dispatch) => {
		dispatch({
			type: TYPE.DELETE,
			payload: {
				index,
			},
		});
	};
}
