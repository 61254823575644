import * as TYPE from './types';

export const initialState = {
	items: [],
};

export default (action, state = initialState) => {
	switch (action.type) {
		case TYPE.ADD:
			return {
				...state,
				items: [...state.items, action.payload],
			};
		case TYPE.DELETE:
			return {
				...state,
				items: state.items.filter((_, index) => index !== action.payload.index),
			};
		default: {
			return state;
		}
	}
};
