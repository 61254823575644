import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks';

function AuthLogout() {
	const navigate = useNavigate();
	const { redirectTo, handleLogout } = useAuth();

	useEffect(() => {
		handleLogout();
	}, []);

	if (redirectTo) {
		return navigate(redirectTo, { replace: true });
	}

	return false;
}

export default AuthLogout;
