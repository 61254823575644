import React, { useEffect } from 'react';
import { useFlow } from 'hooks';
import FlowTableHeader from './flowTableHeader';
import FlowTableRow from './flowTableRow';
import './flow.css';

function Flow() {
	const { items, handleGet } = useFlow();

	useEffect(() => {
		handleGet();
	}, []);

	// https://www.tutorialrepublic.com/codelab.php?topic=bootstrap&file=order-details-table-with-search-filter

	return (
		<main className="container">
			<h1>Flow</h1>

			<table className="table table-striped table-hover table-responsive-md align-middle">
				<FlowTableHeader />
				<tbody>
					{items &&
						items.map((item) => <FlowTableRow item={item} key={item.id} />)}
				</tbody>
			</table>
		</main>
	);
}

export default Flow;
