import { useGlobalStore } from 'store';
import bindActions from 'store/bindActions';
import reducer from 'store/device';

const { actions } = reducer;

const useDevice = () => {
	const { state, dispatch } = useGlobalStore();

	// List of Props
	const { items, error, isLoading } = state.device;

	// List of Actions
	const { handleGetDebug, handleClearDebug } = actions;

	// Bind Actions
	const boundActions = bindActions(
		{
			handleGetDebug,
			handleClearDebug,
		},
		dispatch
	);

	return { items, error, isLoading, ...boundActions };
};

export default useDevice;
