import React from 'react';
import { useNotification } from 'hooks';

function Notification() {
	const { items, handleDelete } = useNotification();

	const notificationIcon = (color) => {
		return (
			<svg
				className="bd-placeholder-img rounded me-2"
				width="20"
				height="20"
				xmlns="http://www.w3.org/2000/svg"
				aria-hidden="true"
				preserveAspectRatio="xMidYMid slice"
				focusable="false"
			>
				<rect width="100%" height="100%" fill={color} />
			</svg>
		);
	};

	const notificationItem = ({ title, message, timestamp }, index) => {
		return (
			<div
				className="toast show"
				role="alert"
				aria-live="assertive"
				aria-atomic="true"
				key={index}
			>
				<div className="toast-header">
					{notificationIcon('#007aff')}
					<strong className="me-auto">{title}</strong>
					<small className="text-muted">{timestamp.toLocaleTimeString()}</small>
					<button
						type="button"
						className="btn-close"
						aria-label="Close"
						onClick={() => handleDelete(index)}
					/>
				</div>
				<div className="toast-body">{message}</div>
			</div>
		);
	};

	return (
		<div aria-live="polite" aria-atomic="true" className="position-relative">
			<div className="toast-container position-absolute top-0 end-0 p-3">
				{items.map((item, index) => notificationItem(item, index))}
			</div>
		</div>
	);
}

export default Notification;
