import * as TYPE from './types';

export const initialState = {
	error: '',
	items: [],
	isLoading: false,
	redirectTo: '',
};

export default (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPE.LOADING: {
			return {
				...state,
				isLoading: true,
				error: '',
				redirectTo: '',
			};
		}
		case TYPE.ERROR: {
			return {
				...state,
				isLoading: false,
				error: payload,
			};
		}
		case TYPE.GET: {
			return {
				...state,
				isLoading: false,
				items: payload,
			};
		}
		case TYPE.NEWVALUE: {
			const { moduleId, deviceKey, status } = payload;
			const updatedGroups = state.items.map((group) => {
				const updatedItems = group.items.map((item) => {
					if (item.module !== moduleId || item.deviceId !== deviceKey) {
						return item;
					}

					return {
						...item,
						values: status,
					};
				});

				return {
					...group,
					items: updatedItems,
				};
			});

			return {
				...state,
				isLoading: false,
				items: updatedGroups,
			};
		}
		case TYPE.TRIGGER: {
			return {
				...state,
				isLoading: false,
			};
		}
		default: {
			return state;
		}
	}
};
