import { useEffect, useRef } from 'react';
import store from 'store/dashboard';
import { logger, createUseMiddlewareReducer } from 'store/handler';

const useDashboard = () => {
	const middlewares = [logger];
	const useMiddlewareReducer = createUseMiddlewareReducer(middlewares);
	const [state, dispatch] = useMiddlewareReducer(
		store.reducer,
		store.initialState
	);

	// List of Props
	const { items, error, isLoading } = state;

	// List of Actions
	// const { handleGet, handleNewValue, handleTrigger } = store.actions;
	const { get, newValue } = store.actions;

	// Bind Actions
	/*
	const boundActions = bindActions(
		{
			handleGet,
			handleNewValue,
			handleTrigger,
		},
		dispatch
	);
	*/

	// Websocket
	const ws = useRef(null);

	useEffect(() => {
		console.log('Connecting...');
		ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
		ws.current.onopen = () => {
			console.log('Request data...');
			ws.current.send(
				JSON.stringify({ type: 'dashboard', message: 'dashboard' })
			);
		};
		ws.current.onclose = () => console.log('Disconnected...');

		return () => {
			console.log('Close websocket');
			ws.current.close();
		};
	}, []);

	useEffect(() => {
		if (!ws.current) return;

		ws.current.onmessage = (e) => {
			const { type, message, data } = JSON.parse(e.data);

			if (type === 'dashboard' && message === 'dashboard') {
				// boundActions.handleGet(data);
				dispatch(get(data));
			} else if (type === 'dashboard' && message === 'newValue') {
				console.log('WS NewValue', data);
				// boundActions.handleNewValue(data);
				dispatch(newValue(data));
			} else if (type === 'flow' && message === 'newDevice') {
				// Ignore
			} else {
				console.log('New event', type, message);
			}
		};
	}, []);

	const itemClickEvent = ({ module, deviceId }) => {
		switch (module) {
			case 'hue':
			case 'zigbee':
				ws.current.send(
					JSON.stringify({ type: module, message: 'toggle', deviceId })
				);
				break;
			default:
				break;
		}
	};

	// Return
	return {
		items,
		error,
		isLoading,
		itemClickEvent,
		handleGet: get,
		handleNewValue: newValue,
	};
};

export default useDashboard;
