import React from 'react';

function Settings() {
	return (
		<main className="container">
			<div className="starter-template text-center py-5 px-3">
				<h1>Settings</h1>
				<p className="lead">
					Use this document as a way to quickly start any new project.
					<br />
					All you get is this text and a mostly barebones HTML document.
				</p>
			</div>
		</main>
	);
}

export default Settings;
