import React from 'react';
import PropTypes from 'prop-types';

const getBoxStyle = {
	light: {
		icon: 'fa-lightbulb',
		bg: 'text-amber',
	},
	temperature: {
		icon: 'fa-thermometer-half',
		bg: 'text-amber',
	},
	lightLevel: {
		icon: 'fa-sun',
		bg: 'text-amber',
	},
	presence: {
		icon: 'fa-walking',
		bg: 'text-blue',
	},
	humidity: {
		icon: 'fa-droplet',
		bg: 'text-amber',
	},
	pressure: {
		icon: 'fa-gauge',
		bg: 'text-amber',
	},
	consumption: {
		icon: 'fa-bolt',
		bg: 'text-amber',
	},
	power: {
		icon: 'fa-bolt',
		bg: 'text-amber',
	},
	speaker: {
		icon: 'fa-headphones',
		bg: 'text-light-green',
	},
	mobile: {
		icon: 'fa-user',
		bg: 'text-light-green',
	},
	network: {
		icon: 'fa-user',
		bg: 'text-light-green',
	},
	door: {
		icon: 'fa-door-open',
		bg: 'text-light-green',
	},
	unknown: {
		icon: 'fa-question',
		bg: 'text-light-green',
	},
};

/* TODO: Temporary fix, may come from backend... */
const getType = (item) => {
	if (item.module === 'sonos') return 'speaker';
	if (item.module === 'unifi') return 'mobile';
	if (['hue', 'zigbee'].includes(item.module)) {
		if (item.data.type === 'ZLLTemperature') return 'temperature';
		if (item.data.type === 'ZHATemperature') return 'temperature';
		if (item.data.type === 'ZLLLightLevel') return 'lightLevel';
		if (item.data.type === 'ZLLPresence') return 'presence';
		if (item.data.type === 'ZHAHumidity') return 'humidity';
		if (item.data.type === 'ZHAPressure') return 'pressure';
		if (item.data.type === 'ZHAConsumption') return 'consumption';
		if (item.data.type === 'ZHAPower') return 'power';
		if (item.data.type === 'ZHAOpenClose') return 'door';

		return 'light';
	}

	return 'unknown';
};

function DashboardCardItem({ item, onClick }) {
	if (!item) return null;

	const { module, name, values } = item;

	if (!values) return null;

	let { description, guiOn } = values;

	if (module === 'sonos') {
		const { currentTrack, state } = values;

		if (state !== 'playing') {
			description = 'Stopped';
		} else if (currentTrack !== undefined) {
			const { artist, title, uri } = currentTrack;

			description = artist !== null ? `${artist} - ${title}` : title;
			if (uri.endsWith('spdif')) description = 'TV';
		}

		guiOn = state === 'playing';
	}

	const type = getType(item);

	const { icon, bg } = getBoxStyle[type] || getBoxStyle.unknown;
	const dataValue = guiOn ? 'on' : 'off';

	const itemClickEvent = () => {
		onClick();
	};

	const itemKeyDownEvent = (e) => {
		if (e.keyCode === 13) onClick();
	};

	const iconSpanClassName = `icon ${bg}`;
	const iconClassnames = `fa-solid fa-fw ${icon}`;

	return (
		<li className="list-group-item">
			<div
				onClick={() => itemClickEvent()}
				onKeyDown={(e) => itemKeyDownEvent(e)}
				role="button"
				tabIndex="0"
			>
				<span className={iconSpanClassName} data-value={dataValue}>
					<i className={iconClassnames} />
				</span>
				{name} <span className="float-end">{description}</span>
			</div>
		</li>
	);
}

DashboardCardItem.propTypes = {
	item: PropTypes.shape({
		name: PropTypes.string,
		deviceId: PropTypes.string,
		module: PropTypes.string,
		type: PropTypes.string,
		position: PropTypes.shape({
			size: PropTypes.string,
		}),
		values: PropTypes.shape({
			description: PropTypes.string,
			guiOn: PropTypes.bool,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
				PropTypes.bool,
			]),
			currentTrack: PropTypes.shape({
				artist: PropTypes.string,
				title: PropTypes.string,
				uri: PropTypes.string,
			}),
			state: PropTypes.string,
		}).isRequired,
	}).isRequired,
	onClick: PropTypes.func.isRequired,
};

export default DashboardCardItem;
