import { useGlobalStore } from 'store';
import bindActions from 'store/bindActions';
import reducer from 'store/logs';

const { actions } = reducer;

const useLogs = () => {
	const { state, dispatch } = useGlobalStore();

	// List of Props
	const { items, error, isLoading } = state.logs;

	// List of Actions
	const { handleGet } = actions;

	// Bind Actions
	const boundActions = bindActions(
		{
			handleGet,
		},
		dispatch
	);

	return { items, error, isLoading, ...boundActions };
};

export default useLogs;
