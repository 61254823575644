import { useGlobalStore } from 'store';
import bindActions from 'store/bindActions';
import reducer from 'store/notification';

const { actions } = reducer;

const useNotification = () => {
	const { state, dispatch } = useGlobalStore();

	// List of Props
	const { items } = state.notification;

	// List of Actions
	const { handleAdd, handleDelete } = actions;

	// Bind Actions
	const boundActions = bindActions(
		{
			handleAdd,
			handleDelete,
		},
		dispatch
	);

	return { items, ...boundActions };
};

export default useNotification;
