import React from 'react';
import PropTypes from 'prop-types';
import { useFlow } from 'hooks';

function FlowTableRow({ item }) {
	const { id, name, active, favorite, voiceControl } = item;
	const { handleTrigger } = useFlow();

	const onTrigger = () => handleTrigger(id);

	const activeClass = active ? 'iconActiveSel' : 'iconActive';
	const favoriteClass = favorite ? 'iconStarSel' : 'iconStar';
	const voiceClass = voiceControl ? 'iconVoiceSel' : 'iconVoice';

	return (
		<tr>
			<th scope="row">{id}</th>
			<td>{name}</td>
			<td>
				<span className={activeClass} />
			</td>
			<td>
				<span className={favoriteClass} />
			</td>
			<td>
				<span className={voiceClass} />
			</td>
			<td>
				<div className="btn-group">
					<button
						type="button"
						className="btn btn-outline-success"
						onClick={() => onTrigger()}
					>
						<i className="fa-solid fa-play" />
					</button>
					<a href="/" className="btn btn-outline-primary">
						<i className="fa-regular fa-pen-to-square" />
					</a>
					<a href="/" className="btn btn-outline-danger">
						<i className="fa-regular fa-trash-can" />
					</a>
				</div>
			</td>
		</tr>
	);
}

FlowTableRow.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		active: PropTypes.number,
		favorite: PropTypes.number,
		voiceControl: PropTypes.number,
	}).isRequired,
};

export default FlowTableRow;
