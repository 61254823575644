import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navigation } from 'components/navigation';
import { Auth, AuthLogout } from 'components/auth';
import { DashboardCard } from 'components/dashboardCard';
import { DeviceDebug } from 'components/device';
import { Flow } from 'components/flow';
import { Logs } from 'components/logs';
import { Notification } from 'components/notification';
import { TriggerDebug } from 'components/trigger';
import { Settings } from 'components/settings';
import Provider from 'store';

import './app.css';
import '@fortawesome/fontawesome-free/css/all.css';

function App() {
	return (
		<BrowserRouter>
			<Provider>
				<Navigation />
				<Notification />

				<Routes>
					<Route path="/auth/logout" element={<AuthLogout />} />
					<Route path="/auth" element={<Auth />} />
					<Route path="/flows" element={<Flow />} />
					<Route path="/logs" element={<Logs />} />
					<Route path="/device/debug" element={<DeviceDebug />} />
					<Route path="/trigger/debug" element={<TriggerDebug />} />
					<Route path="/settings" element={<Settings />} />
					<Route path="/" element={<DashboardCard />} />
					<Route path="/dashboardCard" element={<DashboardCard />} />
				</Routes>

				<footer className="footer mt-auto py-3 bg-light">
					<div className="container">
						<span className="text-muted">Go with the flow!</span>
					</div>
				</footer>
			</Provider>
		</BrowserRouter>
	);
}

export default App;
