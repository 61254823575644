import * as TYPE from './types';

export function get(data) {
	return {
		type: TYPE.GET,
		payload: data,
	};
}

export function newValue(data) {
	return {
		type: TYPE.NEWVALUE,
		payload: data,
	};
}

export function handleGet(widgets) {
	return (dispatch) => {
		dispatch(get(widgets));
	};
}

export function handleNewValue(module, deviceId, updatedValues) {
	return (dispatch) => {
		dispatch(newValue(module, deviceId, updatedValues));
	};
}
