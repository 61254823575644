import React, { useEffect } from 'react';
import { useDevice } from 'hooks';

function DeviceDebug() {
	const { /* error, isLoading, */ items, handleGetDebug, handleClearDebug } =
		useDevice();

	useEffect(() => {
		handleGetDebug();

		return () => {
			handleClearDebug();
		};
	}, []);

	const tableItem = (key, data) => {
		let dataCol = data;

		if (typeof data === 'object' && data !== null) {
			dataCol = JSON.stringify(data, null, 2);
		}

		return (
			<tr key={key}>
				<th scope="row">{key}</th>
				<td>
					<pre>
						<code>{dataCol}</code>
					</pre>
				</td>
			</tr>
		);
	};

	const tableHeader = (module, moduleItems) => {
		return (
			<>
				<thead>
					<tr key={module}>
						<th scope="col">{module}</th>
						<th scope="col">Data</th>
					</tr>
				</thead>
				<tbody>
					{Object.keys(moduleItems).map((key) =>
						tableItem(key, moduleItems[key])
					)}
				</tbody>
			</>
		);
	};

	if (!items) {
		return (
			<main className="container">
				<h1>Devices</h1>
				No devices found.
			</main>
		);
	}

	return (
		<main className="container">
			<h1>Devices</h1>

			<div className="table-responsive">
				<table className="table table-striped table-hover table-responsive-md">
					{Object.keys(items).map((key) => tableHeader(key, items[key]))}
				</table>
			</div>
		</main>
	);
}

export default DeviceDebug;
