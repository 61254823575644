import React, { useEffect } from 'react';
import { useTrigger } from 'hooks';

function TriggerDebug() {
	const { /* error, isLoading, */ items, handleGetDebug, handleClearDebug } =
		useTrigger();

	useEffect(() => {
		handleGetDebug();

		return () => {
			handleClearDebug();
		};
	}, []);

	const tableItem = (item) => {
		const { id } = item;
		let dataCol = item;

		if (typeof item === 'object' && item !== null) {
			dataCol = JSON.stringify(item, null, 2);
		}

		return (
			<tr key={id}>
				<th scope="row">{id}</th>
				<td>
					<pre>
						<code>{dataCol}</code>
					</pre>
				</td>
			</tr>
		);
	};

	return (
		<main className="container">
			<h1>Triggers</h1>

			<div className="table-responsive">
				<table className="table table-striped table-hover table-responsive-md">
					<thead>
						<tr>
							<th scope="col">Id</th>
							<th scope="col">Data</th>
						</tr>
					</thead>
					<tbody>{items.map((item) => tableItem(item))}</tbody>
				</table>
			</div>
		</main>
	);
}

export default TriggerDebug;
