import React from 'react';
import PropTypes from 'prop-types';

function NavbarDropdownItem({ name, to }) {
	return (
		<li>
			<a href={to} className="dropdown-item" target="_blank" rel="noreferrer">
				{name}
			</a>
		</li>
	);
}

NavbarDropdownItem.propTypes = {
	name: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
};

export default NavbarDropdownItem;
