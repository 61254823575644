import React from 'react';
import { useDashboard } from 'hooks';
import DashboardCardItem from './dashboardCardItem';
import './dashboardCard.css';

function DashboardCard() {
	const { items, itemClickEvent } = useDashboard();

	if (!items) {
		return <main className="container">No cards found...</main>;
	}

	return (
		<main className="container">
			<div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4">
				{items.map((group) => (
					<div className="col" key={group.name}>
						<div className="card">
							<div className="card-header text-white bg-secondary">
								{group.name}
							</div>
							<ul className="list-group list-group-flush">
								{group.items.map((item) => (
									<DashboardCardItem
										item={item}
										key={`${item.module}.${item.deviceId}`}
										onClick={() => itemClickEvent(item)}
									/>
								))}
							</ul>
						</div>
					</div>
				))}
			</div>
		</main>
	);
}

export default DashboardCard;
