import * as TYPE from './types';
import apiRequest from '../apiRequest';

function loading() {
	return {
		type: TYPE.LOADING,
	};
}

function getDebug(items) {
	return {
		type: TYPE.GETDEBUG,
		payload: items,
	};
}

function clearDebug() {
	return {
		type: TYPE.CLEARDEBUG,
		payload: {},
	};
}

function error(e) {
	return {
		type: TYPE.ERROR,
		payload: e,
	};
}

export function handleGetDebug() {
	return async (dispatch) => {
		dispatch(loading());
		try {
			const { data } = await apiRequest('/api/device/status', 'GET', {}, {});
			dispatch(getDebug(data));
		} catch (e) {
			dispatch(error(e.response.data));
		}
	};
}

export function handleClearDebug() {
	return (dispatch) => {
		dispatch(clearDebug());
	};
}
