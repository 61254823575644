import React from 'react';

function FlowTableHeader() {
	return (
		<thead>
			<tr>
				<th scope="col">Id</th>
				<th scope="col">Name</th>
				<th scope="col">Active</th>
				<th scope="col">Favorite</th>
				<th scope="col">Voice control</th>
				<th scope="col">Action</th>
			</tr>
		</thead>
	);
}

export default FlowTableHeader;
